<template>
    <div id="app" class="app-wrap">
        <!-- 路由出口 -->
        <router-view />
    </div>
</template>

<script>
export default {
  name: "layout",
  components: {
  },
};
</script>

<style scoped lang="less">

</style>
